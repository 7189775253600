<template>
  <div>
    <!-- <h4 class="header-title">List Quotation</h4> -->
    <vue-element-loading
      :active="isLoadingTable"
      spinner="bar-fade-scale"
      color="#FF6700"
    />
    <div class="row">
      <div class="col-lg-3">
        <router-link
          v-can="'QUOTATION_CREATE'"
          :to="{ name: 'Quotation Create' }"
          class="btn btn-create"
        >
          <i class="mdi mdi-plus-circle mr-1"></i>
          Create New
        </router-link>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-9">
        <div class="float-lg-right float-left">
          <div class="form-inline">
            <label class="mb-2 mr-sm-2">Search :</label>
            <input
              v-model="filterText"
              type="text"
              class="form-control form-control-sm mb-2"
              placeholder="Search . . ."
              @keyup="doFilter"
            />
            <!-- <button type="button" class="btn btn-primary mb-2 mr-sm-2" @click="doFilter">Go</button> -->
            <!-- <button type="button" class="btn btn-danger mb-2 mr-sm-2" @click="resetFilter">Reset</button> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div class="table-responsive">
      <vuetable
        ref="vuetable"
        :per-page="perPage"
        :fields="fields"
        :api-url="apiUrl"
        :api-mode="true"
        :http-options="httpOptions"
        :show-sort-icons="true"
        :append-params="moreParams"
        pagination-path
        class="table table-centered table-striped"
        data-path="data"
        @vuetable:loading="loadingTable"
        @vuetable:load-success="loadTableSuccess"
        @vuetable:load-error="loadTableFailed"
        @vuetable:pagination-data="onPaginationData"
      >
        <template slot="actions" slot-scope="props">
          <div class="table-button-container">
            <a
              v-can="'QUOTATION_VIEW'"
              :href="urlPdf"
              target="_blank"
              class="action-icon mr-1"
              @click="onAction('view-pdf', props.rowData, props.rowIndex)"
            >
              <i class="fas fa-download"></i>
            </a>
            <!--						<a v-can="'QUOTATION_VIEW'" href="javascript:void(0);" class="action-icon  mr-1"-->
            <!--							 style="background: #ffa207;"-->
            <!--							 @click="onAction('view-item', props.rowData, props.rowIndex)">-->
            <!--							<i class="mdi mdi-eye"></i>-->
            <!--						</a>-->
            <a
              v-can="'QUOTATION_UPDATE'"
              href="javascript:void(0);"
              class="action-icon  mr-1"
              @click="onAction('edit-item', props.rowData, props.rowIndex)"
            >
              <i class="fas fa-edit"></i
                      >
            </a>
            <a
              v-can="'QUOTATION_DELETE'"
              href="javascript:void(0);"
              class="action-icon "
              @click="onAction('delete-item', props.rowData, props.rowIndex)"
            >
              <i class="fas fa-trash-alt"></i
                      >
            </a>
          </div>
        </template>
      </vuetable>
    </div>
    <!-- end table -->

    <!-- pagination -->
    <div class="row">
      <div class="col-md-5">
        <div class="row no-gutters">
          <div class="col-lg-3">
            <div class="form-group float-lg-left">
              <select
                v-model="perPage"
                class="form-control form-control-sm"
                @change="onChangeTableLength"
              >
                <option v-for="opt in lengthMenu" :key="opt" :value="opt">
                  {{ opt }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-8">
            <vuetable-pagination-info
              ref="paginationInfo"
              class="
                justify-content-center justify-content-lg-start
                text-center text-lg-left
              "
            >
            </vuetable-pagination-info>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-7">
        <vuetable-pagination
          ref="pagination"
          :css="css.pagination"
          class="
            pagination pagination-rounded
            justify-content-center justify-content-lg-end
          "
          @vuetable-pagination:change-page="onChangePage"
        >
        </vuetable-pagination>
      </div>
    </div>
    <!-- end pagination -->
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import { authMethods } from '@state/helpers'
import { mapActions } from 'vuex'
import axios from 'axios'

var url = '/quotation'

for (
  let i = 0;
  i < JSON.parse(localStorage.getItem('userPermissions')).length;
  i++
) {
  // const element = array[i];
  // if (! JSON.parse(localStorage.getItem('userPermissions'))
  //       .includes(binding.expression
  //         .replace(/'/g, "")
  //           .replace(/"/g, ""))) {
  //      url = '/quotation';
  // }else{
  //   url = '/quotation?admin';
  // }
  // console.log(JSON.parse(localStorage.getItem('userPermissions'))[i])
  if (
    JSON.parse(localStorage.getItem('userPermissions'))[i] ===
    'CRM_ADMIN_ENABLE'
  ) {
    url = '/quotation?admin=true'
  }
}
console.log(url, 'url')

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
  },
  props: {
    showActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moreParams: {},
      filterText: '',
      deleteData: {},
      isLoadingTable: false,
      httpOptions: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      },
      apiUrl: this.$store.state.rootApi + url,
      urlPdf: '#',
      items: [
        {
          text: 'Master',
          href: '/',
        },
        {
          text: 'Quotation',
          href: '/quotation',
        },
        {
          text: 'List',
          active: true,
        },
      ],
      lengthMenu: [5, 10, 25, 50, 100],
      perPage: 5,
      fields: [
        {
          name: 'number',
          title: 'Number',
          // sortField: 'number',
        },
        {
          name: 'subject',
          title: 'Subject',
          // sortField: 'subject',
        },
        {
          name: 'customer_name',
          title: 'Customer',
          // sortField: 'customer_id',
        },
        {
          name: 'pic_name',
          title: 'Pic',
          // sortField: 'pic_name',
        },
        {
          name: 'pol.name_port',
          title: 'Pol',
          // sortField: 'pol',
        },
        {
          name: 'pod.name_port',
          title: 'Pod',
          // sortField: 'pod',
        },
        {
          name: 'actions',
          title: 'Actions',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
      ],
      css: {
        pagination: {
          activeClass: 'btn-primary text-white',
          pageClass: 'btn btn-sm ',
          linkClass: 'btn btn-sm',
          icons: {
            prev: 'fas fa-arrow-left',
            next: 'fas fa-arrow-right',
          },
        },
      },
    }
  },
  mounted() {},
  methods: {
    ...authMethods,
    loginByToken() {
      console.log('login by token')
    },

    ...mapActions('quotation', ['deleteQuotation', 'generatePdf']),
    doFilter() {
      this.onFilterSet(this.filterText)
    },
    /**
     * Table Length Change
     *
     * @return  {[type]}  [return description]
     */
    onChangeTableLength() {
      // this.$refs.vuetable.reload()
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    /**
     * Pagination page change
     */
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    /**
     * Pagination info and pagination show
     */
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    /**
     * [onFilterSet description]
     *
     * @param   {[type]}  filterText  [filterText description]
     *
     * @return  {[type]}              [return description]
     */
    onFilterSet(filterText) {
      this.moreParams = {
        search: filterText,
      }
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    /**
     * Show Table Loading
     *
     * @return  {[type]}  [return description]
     */
    loadingTable() {
      this.isLoadingTable = true
    },

    /**
     * Hide Loading if Success
     *
     * @return  {[type]}  [return description]
     */
    loadTableSuccess() {
      this.isLoadingTable = false
    },
    /**
     * Hide Loading if Failed
     *
     * @return  {[type]}  [return description]
     */
    loadTableFailed(error) {
      console.log(error)

      this.isLoadingTable = false
      this.$swal({
        title: 'Terjadi Kesalahan',
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
      // }
    },

    onAction(action, rowData, rowIndex) {
      if (action === 'edit-item') {
        this.$router.push({
          name: 'Quotation Edit',
          params: {
            id: rowData.id,
          },
        })
      } else if (action === 'detail-item') {
        this.$router.push({
          name: 'Quotation Detail',
          params: {
            id: rowData.id,
          },
        })
      } else if (action === 'view-item') {
        this.$router.push({
          name: 'Quotation View',
          params: {
            id: rowData.id,
          },
        })
      } else if (action === 'delete-item') {
        this.showConfirmDelete(rowData.id)
      } else if (action === 'view-pdf') {
        let config = {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          },
        }
        axios({
          url: this.$store.state.rootApi + '/quotation' + '/pdf/' + rowData.id,
          config,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fURL = document.createElement('a')

          fURL.href = fileURL
          fURL.setAttribute('download', 'Quotation ' + rowData.number + '.pdf')
          document.body.appendChild(fURL)

          fURL.click()
        })
      }
    },

    showConfirmDelete(id) {
      this.deleteData.id = id
      let self = this
      // this.$swal({
      // 	title: 'Are you sure?',
      // 	text: 'You will not be able to recover this!',
      // 	type: 'warning',
      // 	showCancelButton: true,
      // 	confirmButtonText: 'Yes, delete it!',
      // 	cancelButtonText: 'No, keep it',
      // }).then((result) => {
      // 	if (result.value) {
      // 		this.$swal('Deleted!', 'Your data has been deleted.', 'error')
      // 		self.onDelete()
      // 	} else if (result.dismiss) {
      // 		this.$swal('Cancelled', 'Your data is safe :)', 'error')
      // 	}
      // })
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 107, 85)',
        cancelButtonColor: '#C1C1C1',
        reverseButtons: true,
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          self.onDelete()
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
        }
      })
    },
    /**
     * Delete Brand
     *
     * @return  {[type]}  [return description]
     */
    onDelete() {
      // this.isLoadingTable = true
      this.deleteQuotation(this.deleteData.id)
        .then((res) => {
          this.successAlert()
          // this.isLoadingTable = false;
          this.$refs.vuetable.refresh()
        })
        .catch((err) => {
          this.errorAlert()
          console.log(err.response.message)

          // this.isLoadingTable = false;
        })
    },
  },
}
</script>
